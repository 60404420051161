/** @format */

import React, { useEffect, useState } from "react";
import "./Home.css";
import Homefooter from "./Homefooter";
import Rating from "./Dropdown/Rating";
import Insurance from "./Insurance";

import Asian from "../Images/webp/Asian Vascular Hospitals.webp";
import praveen from "../Images/webp/SUB08465.webp";
import { Link } from "react-router-dom";
import Avhservice1 from "../Images/webp/vascular.webp";
import Avhservice2 from "../Images/webp/cardio_vascular.webp";
import neurovascular from "../Images/webp/Neurovascular.webp";
// import stemcell from "../Images/webp/Stemcell.webp";
import achievement_image from "../Images/webp/friendly-doctor-hands-holding-patient-hand-support-2023-11-27-05-13-53-utc.webp";
import praveen_kumar from "../Images/webp/Dr. G.V. Praveen Kumar.webp";
import nirupama_devi from "../Images/webp/Dr. A. Nirupama Devi.webp";
import laxmi_narayana from "../Images/webp/Dr. A. Laxminarayana.webp";

import month_image from '../Images/webp/Diabetes_Awareness_Badge.png'


// import landing from '../Images/webp/AVH_Landingpage_DiabeticFoot_2-01_11zon_11zon.webp'

// import loading_img from '../Images/AVH_Symbol_Plain.png'

import CountUpSection2 from "./Dropdown/Count2";

const Home = () => {
  const statics = "Statistics 2023-24:";
  const maxCounts = [532, 30000, 95, 90]; // Maximum count values for each card
  const count_names = [
    "Camps Conducted",
    "Successful Surgeries",
    "AV Fistula Creation Success",
    "Overall Vascular Care",
  ];
  const symb = ["+", "+", "%", "%"];

  useEffect(() => {
    document.title = "AVH - Home";
  }, []);

  // const [load, setload] = useState(true);

  // useEffect(() => {
    // setTimeout(() => {
      // setload(true);
    // }, 1000);
  // }, []);

  return (
    <div className="">
      
        <div>
          <section className="home ">
            <div className="home_txt">
              <h1 className="">
                Leading Specialists in <br />
                Vascular Treatments
                <br /> in India
              </h1>


              {/* <div className="month_main">

              <div className="home_db_txt_main">
              <div className="home_db_txt_sub">
                <p className="db_p_1">14 <sup>th</sup> November - 20 <sup>th</sup> December</p>
                <p className="db_p">As Part of the Diabetes Awareness Month we are bringing in the initiative With <b>Free Diabetes Health Evaluation</b> (Worth ₹5000): </p>
                <ol>
                  <li>Blood Sugar Tests (RBS, FBS, PLBS)</li>
                  <li>Evaluation of Diabetic Foot</li>
                  <li>Ankle-Brachial Index (ABI) Test</li>
                  <li>Peripheral Artery Disease (PAD) Screening</li>
                  <li>Free Consultation</li>
                </ol>
              </div>

              <div className="home_btns">
                <Link to={'/diabetesawareness'} className="btn home_land_btns">Know More</Link>
                <Link to={'/Appointment'} className="btn home_land_btns">Appointment</Link>
              </div>
              </div>
                <img src={month_image} alt="" className="month_image"/>
              </div> */}

            </div>
          </section>
          {/* <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQO8AAxAr9ZNr9JCwn2QZ35rSWWuiayhh0ayQ&s'} alt="" className="badge"/> */}


          {/* about */}

          <section className="">
            <div className="d-flex about_main">
              <div className="abt_txt" data-aos="fade-right">
                <p>About</p>
                <div className="abt_txt_head">
                  <h1>
                    Why <br />
                    Asian Vascular
                  </h1>
                  <h1>Hospitals?</h1>
                </div>
              </div>
              <div className="abt_image_main d-flex gap-5 align-items-center">
                <div className="abt_image" data-aos="fade-up">
                  <img className="abt_img" src={Asian} alt="" />
                  <div className="" data-aos="fade-up"></div>
                </div>
                <div className="abt_lrm" data-aos="fade-up">
                  <p>
                    We are distinctive from a regular commercial hospital, we've
                    been treating the patients with the utmost care and
                    precision manoeuvring over <b>30,000</b> successful
                    surgeries since our short emergence and nursing the patients
                    back to their confident healthy selves. Apart from regular
                    surgeries, we heaved complicated cases that kept defying the
                    odds persistently where other hospitals have given up on the
                    patients significantly impacting their golden lives.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="home_ser" data-aos="fade-up">
            <div className="d-grid justify-content-around">
              <div className=" ser_main d-flex align-items-center gap-5">
                <div className="ser_sub d-flex gap-5 align-items-center">
                  <div className="">
                    <div
                      className="ser_images ser_images_1"
                      data-aos="fade-right"
                    >
                      <img src={Avhservice1} alt="" />
                      <h1>Vascular</h1>
                    </div>
                    <div
                      className="ser_images ser_images_1"
                      data-aos="fade-right"
                    >
                      <img src={neurovascular} alt="" />
                      <h1>Neurovascular</h1>
                    </div>
                  </div>
                  <div className="ser_txt " data-aos="fade-up">
                    <p>Services</p>
                    <h2 className="hm_ser">
                      At Asian Vascular Hospitals, excel would belittle our
                      Mastership in providing a Pinnacle of Healthcare in these
                      critical Vascular and Cardiovascular Departments.
                    </h2>
                  </div>
                </div>
                <div className="">
                  <div className="ser_images ser_images_2" data-aos="fade-up">
                    <img src={Avhservice2} alt="" />
                    <h1>Cardio Vascular</h1>
                  </div>
                  {/* <div className="ser_images ser_images_2" data-aos="fade-up">
                    <img src={stemcell} alt="" />
                    <h1>Stemcell Therapeutics</h1>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          <div className="doctors_float">
            <section className="doctors" data-aos="fade-up">
              <div className=" dox_sec text-center">
                <div className="doctors_head mb-5" data-aos="fade-up">
                  <h1>Meet Our Specialists</h1>
                </div>
                <div className="doc_main d-flex justify-content-center">
                  <div
                    className="doctors_images doctors_images_1"
                    data-aos="zoom-in"
                  >
                    <img src={praveen_kumar} alt="" />
                    <div className="doc_txt">
                      <h1 className="fs-3">Dr. G.V. Praveen Kumar</h1>
                      <p>Founder Chairmen & Chief Consultant</p>
                      <p>Vascular & Endovascular Surgeon</p>
                      <p>MS, DNB (Vascular Surgery)</p>
                    </div>
                  </div>

                  <div
                    className="doctors_images doctors_images_2"
                    data-aos="zoom-in"
                  >
                    <img src={nirupama_devi} alt="" />
                    <div className="doc_txt doc_txt_2">
                      <h1 className="fs-3">Dr. A. Nirupama Devi</h1>
                      <p> Managing Director & Consultant Pulmonologist</p>
                      <p> MBBS, DTCD</p>
                    </div>
                  </div>

                  <div
                    className="doctors_images doctors_images_3"
                    data-aos="zoom-in"
                  >
                    <img src={laxmi_narayana} alt="" />
                    <div className="doc_txt">
                      <h1 className="fs-3">Dr. A. Laxminarayana</h1>
                      <p>Cardio Thoracic Vascular Surgeon</p>
                      <p style={{ paddingBottom: "20px" }}>MBBS, M.CH</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="view_more_btn">
              <Link to={"/Ourdoctors"}>
                <span className="our_doctors_more">
                  View More<small>Doctors</small>
                  <i className="fa-solid fa-arrow-right"></i>{" "}
                </span>
              </Link>
            </div>
          </div>

          <section className="concern" data-aos="fade-up">
            <div className="d-flex about_main about_main_3">
              <div className="abt_txt" data-aos="fade-right">
                <div className="abt_txt_head abt_txt_head_3">
                  <h1>
                    Your Concern, <br /> Our <br />
                    Mastery!
                  </h1>
                </div>
              </div>
              <div className="abt_image_main d-flex gap-5 align-items-center">
                <div className="abt_image abt_image_3" data-aos="fade-right">
                  <img className="abt_img" src={praveen} alt="" />
                </div>
                <div className="abt_lrm abt_lrm_3" data-aos="fade-up">
                  <p>
                    We stand by our word when we proclaim our proficiency in
                    handling over <b> 20,000</b> successful surgeries comprising
                    various complications, concerns and a broad spectrum of
                    rejected cases of other prominent hospitals.
                  </p>

                  <ul>
                    <li>Varicose Veins</li>
                    <li>Deep Vein Thrombosis (DVT)</li>
                    <li>Aneurysms</li>
                    <li> Peripheral Neuropathy</li>
                    <li>Vascular Malformations</li>
                    <li>AV Fistula Creation</li>
                    <li>Arterial Diseases</li>
                  </ul>

                  <Link to={"/Contact"} className="btn btn_contact">
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section id="achievement">
            <div
              className="doctors_head mb-5 qualities_head"
              data-aos="fade-up"
            >
              <h1 className="">Celebrate every milestone</h1>
            </div>
            <div className="achive_main">
              <ul className="achieve_left" data-aos="fade-right">
                <li>AV Fistula Creation in the shortest time</li>
                <li>10 Years of Exclusive Vascular Care</li>
                <li>Multiple approaches to arterial Diseases</li>
                <li>Salvaging of Limbs in arterial diseases</li>
                <li>Embolizations</li>
                <li>Aortic aneurysm Care</li>
                <li>
                  Successfully procedures of severe Trauma Cases with retention
                  of limbs through precise anastomosis
                </li>
              </ul>
              <div className="achieve_right" data-aos="fade-up">
                <img src={achievement_image} alt="" />
              </div>
            </div>
          </section>

          <div className="home_count">
            <CountUpSection2
              maxCounts={maxCounts}
              count_names={count_names}
              statics={statics}
              symb={symb}
            />
          </div>

          <div className="home_rating">
            <Rating />
          </div>

          <div className="home_insurance">
            <Insurance />
          </div>

          <div className="">
            <Homefooter />
          </div>
        </div>
      
    </div>
  );
};

export default React.memo(Home);
