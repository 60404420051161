import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import './Dashboard.css'
import * as XLSX from 'xlsx'
// import { List,AutoSizer,CellMeasurerCache,CellMeasurer } from 'react-virtualized';

const Dashboard = () => {

    const [form_data,setform_data] = useState([])
    const [chatbot_data,setchatbot_data] = useState([])

    const [data_replace,setdata_replace] = useState(true)



    const get_data = async ()=>{

        try {
            const response = await axios.get('https://avh-database-default-rtdb.firebaseio.com/form_data.json');
            if (response.data) {
              const fetchedData = Object.keys(response.data).map((key) => ({
                id: key,
                ...response.data[key],
              }));
              setform_data(fetchedData);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
       
    }

    const getbot_data = async ()=>{

        try {
            const response = await axios.get('https://avh-database-default-rtdb.firebaseio.com/chatbot_data.json');
            if (response.data) {
              // Convert Firebase object to an array
              const fetchedData = Object.keys(response.data).map((key) => ({
                id: key,
                ...response.data[key],
              }));
            //   console.log(fetchedData)
              setchatbot_data(fetchedData);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
       
    }


    useEffect(()=>{
        get_data()
        getbot_data()
    },[])
    // console.log(form_data)



    const data_change = (e) =>{
        if(e.target.value === 'Chat'){
            setdata_replace(false)
        }
        else if(e.target.value === 'Form'){
            setdata_replace(true)
        }
    }


    const handle_sort = (e)=>{
        if(e.target.value === 'new_date'){
            const sortedItems = form_data.filter(e=>e.Register_Date).sort((a,b)=>new Date(b.Register_Date) - new Date(a.Register_Date))
            setform_data(sortedItems)
        }
        else if(e.target.value === 'date_old'){
            const sortedItems = form_data.filter(e=>e.Register_Date).sort((a,b)=>new Date(a.Register_Date) - new Date(b.Register_Date))
            setform_data(sortedItems)
            console.log(sortedItems)

        }
        else if(e.target.value === 'a-z'){
            const sortedItems = form_data.filter(e=>e).sort((a,b)=>a.First_Name.localeCompare(b.First_Name))
            setform_data(sortedItems)
        }
        else if(e.target.value === 'z-a'){
            const sortedItems = form_data.filter(e=>e).sort((a,b)=>b.First_Name.localeCompare(a.First_Name))
            setform_data(sortedItems)
        }
        else if(e.target.value === 'fam_a-z'){
            const sortedItems = form_data.filter(e=>e).sort((a,b)=>a.Family_Name.localeCompare(b.Family_Name))
            setform_data(sortedItems)
        }
        else if(e.target.value === 'fam_z-a'){
            const sortedItems = form_data.filter(e=>e).sort((a,b)=>b.Family_Name.localeCompare(a.Family_Name))
            setform_data(sortedItems)
        }
    }


    const handle_sort_two = (e)=>{
        if(e.target.value === 'new_date'){
            const sortedItems = chatbot_data.filter(e=>e.Register_date).sort((a,b)=>new Date(b.Register_date) - new Date(a.Register_date))
            setchatbot_data(sortedItems)
        }
        else if(e.target.value === 'date_old'){
            const sortedItems = chatbot_data.filter(e=>e.Register_date).sort((a,b)=>new Date(a.Register_date) - new Date(b.Register_date))
            setchatbot_data(sortedItems)
            console.log(sortedItems)

        }
        else if(e.target.value === 'a-z'){
            const sortedItems = chatbot_data.filter(e=>e).sort((a,b)=>a.name.localeCompare(b.name))
            setchatbot_data(sortedItems)
        }
        else if(e.target.value === 'z-a'){
            const sortedItems = chatbot_data.filter(e=>e).sort((a,b)=>b.name.localeCompare(a.name))
            setchatbot_data(sortedItems)
        }
    }
    // const search_val = useRef()

    // const inp_val = (e)=>{
    //     if(e.target.value !==''){
    //         const search_fil = form_data.filter(k=>k.First_Name.includes(search) )
    //         setform_data(search_fil)
    //     }
    //     else{
    //         get_data()
    //     }
    // }

    const downloadExcel = () => {

        if(data_replace){
            const filtereddata = form_data.map(({id,...rest})=>rest)

            const workbook = XLSX.utils.book_new();
    
            // Convert JSON data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(filtereddata);
        
            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        
            // Generate an Excel file and download it
            XLSX.writeFile(workbook, "Form_data.xlsx");
        }
        else{
            const filtereddata = chatbot_data.map(({id,...rest})=>rest)

            const workbook = XLSX.utils.book_new();
    
            // Convert JSON data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(filtereddata);
        
            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        
            // Generate an Excel file and download it
            XLSX.writeFile(workbook, "ChatBot_data.xlsx");
            
        }

        // Create a new workbook
       
      };

    


    return (
        <div>
            <div className="dash">
                <div className="d-flex align-items-center justify-content-between">
                    <h1 className='mb-3'>Dashboard</h1>
                    <div className="d-flex align-items-center gap-2">
                        <select name="" id="" onChange={data_change}>
                            <option value="Form">Form Data</option>
                            <option value="Chat">Chat Bot</option>
                        </select>
                        {data_replace && <select name="" id="" onChange={handle_sort}>
                            <option value="new_date">Sort By Latest Date</option>
                            <option value="date_old">Sort By Oldest Date</option>
                            <option value="a-z">Sort By First Name (A-Z)</option>
                            <option value="z-a">Sort By Last Name (Z-A)</option>
                            <option value="fam_a-z">Sort By Family Name (A-Z)</option>
                            <option value="fam_z-a">Sort By Family Name (Z-A)</option>
                        </select>}

                        {!data_replace && <select name="" id="" onChange={handle_sort_two}>
                            <option value="new_date">Sort By Latest Date</option>
                            <option value="date_old">Sort By Oldest Date</option>
                            <option value="a-z">Sort By Name (A-Z)</option>
                            <option value="z-a">Sort By Name (Z-A)</option>
                            {/* <option value="fam_a-z">Sort By Family Name (A-Z)</option>
                            <option value="fam_z-a">Sort By Family Name (Z-A)</option> */}
                        </select>}
                        <div className="btn dwld" onClick={downloadExcel}>Download</div>
                        {/* <input type="search" className='data_search' placeholder='Search Name' ref={search_val} onChange={inp_val}/> */}
                    </div>
                </div>
                <table>
                   {data_replace && <tr>
                        
                        {/* Form data */}

                        <th>First Name</th>
                        <th>Family Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Questions</th>
                        <th>Subject</th>
                        <th>Register Date</th>
                        <th>Register Time</th>
  
                    </tr>}

                    {!data_replace && <tr>
                        <th>Name</th>
                        <th>Age</th>
                        <th>Disease</th>
                        <th>Habits & Conditions</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Location</th>
                        <th>Book Appointment</th>
                        <th>Register Date</th>
                        <th>Register Time</th>
                    </tr>}

                    
                        {data_replace && form_data.map(e=>{
                            return(
                                 <tr key={e.id}>

                                    {/* Form data */}
                                    <td>{e.First_Name}</td>
                                    <td>{e.Family_Name}</td>
                                    <td>{e.Email}</td>
                                    <td>{e.Phone_Number}</td>
                                    <td>{e.Questions}</td>
                                    <td>{e.Subject}</td>
                                    <td>{e.Register_Date}</td>
                                    <td>{e.Register_Time}</td>

                                </tr>

                            )
                        })}



                        {!data_replace && chatbot_data.map(e=>{
                            return(
                                 <tr key={e.id}>
                                    {/* Chat Bot Data */}
                                    <td>{e.name}</td>
                                    <td>{e.age}</td>
                                    <td>{e.Disease}</td>
                                    <td>{e.Habits_Conditions}</td>
                                    <td>{e.Phone_Number}</td>
                                    <td>{e.email}</td>
                                    <td>{e.Location}</td>
                                    <td>{e.Appointment}</td>
                                    <td>{e.Register_date}</td>
                                    <td>{e.Register_time}</td>
                                </tr>

                            )
                        })}
                    
                </table>
            </div>
        </div>
    );
};

export default Dashboard;