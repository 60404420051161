import React from 'react';
import '../Dropdown/Surgeries.css'

const Surgeries = (p) => {
    return (
        <div>
            <div className="surgeries_main d-flex align-items-center justify-content-center flex-wrap gap-3">
                <div className="surgeries_heading">
                    <h5>{p.sur_head}</h5>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Surgeries);