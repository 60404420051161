// import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ChatBot from 'react-simple-chatbot';
// import {Segment} from 'semantic-ui-react'
import { ThemeProvider } from 'styled-components';
// import './App.css'
import './Chatbot.css'
// import robot from '../Images/robot.png'
import bot_avatar from '../Images/robot-assistant.png'
import robot from '../Images/AVH_ChatBot_LogoAnimation_1.gif'
import bot_user from '../Images/user (1).png'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
// import robo from '../Images/chatbot (1).png'


// import { Segment } from 'semantic-ui-react';
// import { ThemeProvider } from 'styled-components';

const Chat = () => {

  const locationserv = useLocation()

  const services =  locationserv.pathname === '/Dashboard'

  const [isOpen, setIsOpen] = useState(false);
  
  const [close,setclose] = useState(false)

  const theme = {
    background: '#f5f8fb', // Background color of the chatbot
    fontFamily: "'Times New Roman', Times, serif",
    headerBgColor: '#132573', // Header background color
    headerFontColor: '#fff', // Header text color
    headerFontSize: '15px',
    botBubbleColor: '#132573', // Background color of bot messages
    botFontColor: '#fff', // Text color of bot messages
    userBubbleColor: '#fff', // Background color of user messages
    userFontColor: '#4a4a4a', // Text color of user messages
  };




   const chatbot1 = useRef()
   const chatbot2 = useRef()

  //  const close_chat = () =>{
  //   setclose(false)
  //  }


   useEffect(()=>{
    setTimeout(()=>{
      if(!services){
        chatbot2.current.style.display = 'block'
      }
     },5000)  
   },[])

   const close_chat = () =>{
    chatbot2.current.style.display = 'none'
    setclose(true)

   }


    const steps = [
      {
        id:'Welcome',
        message:'Please Select a Language',
        trigger:'lan'
      },
      {
        id:'lan',
        options:[
          {
            value:'English',
            label:'English',
            trigger:'Great'
          },
          {
            value:'Hindi',
            label:'Hindi',
            trigger:'Greathin'
          },
          {
            value:'Telugu',
            label:'Telugu',
            trigger:'Greattel'
          },

        ],
      },
      {
        id:'Great',
        message:'Welcome to True Care of Asian Vascular Hospitals',
        trigger:'name'
      },
      {
        id:'Greathin',
        message:'एशियन वैस्कुलर हॉस्पिटल्स की ट्रू केयर में आपका स्वागत है',
        trigger:'namehin'
      },
      {
        id:'Greattel',
        message:'ఆసియన్ వాస్కులర్ హాస్పిటల్స్ యొక్క ట్రూ కేర్‌కు స్వాగతం',
        trigger:'nametel'
      },
      {
        id:'name',
        message:'Please Enter Your Name',
        trigger:'typ_name'
      },
      {
        id:'namehin',
        message:'कृपया अपना नाम दर्ज करें',
        trigger:'typ_namehin'
      },
      {
        id:'nametel',
        message:'దయచేసి మీ పేరును నమోదు చేయండి',
        trigger:'typ_nametel'
      },
      {
        id:'typ_name',
        user:true,
        validator: (value) => {
          const nameRegex = /^[a-zA-Z\s]+$/; // Allows alphabets and spaces only
          if (!value.trim()) {
            return 'Name cannot be empty.';
          }
          if (value.length < 2) {
            return 'Name must be at least 2 characters long.';
          }
          if (!nameRegex.test(value)) {
            return 'Name can only contain alphabets and spaces.';
          }
          return true;
        },
        trigger:'age'
      },
      {
        id:'typ_namehin',
        user:true,
        trigger:'agehin'
      },
      {
        id:'typ_nametel',
        user:true,
        trigger:'agetel'
      },
      {
        id:'age',
        message:'Hi {previousValue}, Please Enter Your Age',
        trigger:'typ_age'
      },
      {
        id:'agehin',
        message:'नमस्ते {previousValue}, कृपया अपनी आयु दर्ज करें',
        trigger:'end'
      },
      {
        id:'agetel',
        message:'హాయ్ {previousValue}, దయచేసి మీ వయస్సును నమోదు చేయండి',
        trigger:'end'
      },
      {
        id:'typ_age',
        user:true,
        validator:(value)=>{
          const age = parseInt(value, 10);
          if (isNaN(age) || age < 1 || age > 120) {
            return 'Please enter a valid age between 1 and 120.';
          }
          return true;
        },
        trigger:'disease'
      },
      {
          id:'disease',
          message:'Please Select Disease',
          trigger:"dis_choice"
        },
        {
          id:'dis_choice',
          options:[
          {
            value:'Varicose Veins / Veinous Insufficiency',
            label:' Varicose Veins / Veinous Insufficiency',
            trigger:'hc_habits'
          },
          {
            value:'Peripheral Arterial Disease(PAD)',
            label:'Peripheral Arterial Disease(PAD)',
            trigger:'hc_habits'
          },
          {
            value:'Deep Vein Thrombosis (DVT)',
            label:' Deep Vein Thrombosis (DVT)',
            trigger:'hc_habits'
          },
          {
            value:'Heart Condition',
            label:'Heart Condition',
            trigger:'hc_habits'
          },
          {
            value:'Others',
            label:'Others',
            trigger:'hc_habits'
          },
        ]
        },

      {
        id:'hc_habits',
        message:'Please Select Habits & Conditions',
        trigger:'hc_habits_list'
        
      },
      {
        id:'hc_habits_list',
        options:[
          {
            value:'Smoking ',
            label:"Smoking ",
            trigger:"hc_contact"
          },
          {
            value:'Diabetes',
            label:"Diabetes",
            trigger:"hc_contact"
          },
          {
            value:'HyperTension',
            label:"HyperTensio",
            trigger:"hc_contact"
          },
          {
            value:'Obesity',
            label:"Obesity",
            trigger:"hc_contact"
          },
          {
            value:'Family History of PAD, Varicose Veins',
            label:"Family History of PAD, Varicose Veins",
            trigger:"hc_contact"
          },
          {
            label:"History of Injury",
            value:'History of Injury',
            trigger:"hc_contact"
          },
          {
            label:"None",
            value:'None',
            trigger:"hc_contact"
          },
        ]
      },

    



      {
        id:'hc_contact',
        message:"Please Enter Your Mobile Number",
        trigger:"type_hc_contact"
      },
      {
        id:'type_hc_contact',
        user:true,
        validator:(value)=>{
          const phoneRegex = /^[0-9]{10}$/; // Accepts 10 digits
          if (!phoneRegex.test(value)) {
            return 'Please enter a valid 10-digit phone number.';
          }
        return true;
        },
        trigger:"hc_email"
      },
      {
        id:'hc_email',
        message:'Enter Email Address (Optional), Select (Yes) or (No)',
        trigger:'hc_sel_email'
      },
      {
        id:'hc_sel_email',
        options:[
          {
            value:'Yes',
            label:'Yes',
            trigger:'hc_ent_email'
          },
          {
            Value:'NO',
            label:'No',
            trigger:'hc_address'
          },
        ]
      },
      {
        id:'hc_ent_email',
        message:'Please Enter Your Email',
        trigger:'hc_write_email'
      },
      {
        id:"hc_write_email",
        user:true,
        validator: (value) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            return 'Please enter a valid email address.';
          }
          return true;
        },
        trigger:'hc_address'
      },
      {
        id:'hc_address',
        message:'Please Enter Your Location (City/Town/Village), State',
        trigger:'hc_loc'
      },
      {
        id:'hc_loc',
        user:true,
        validator: (value) => {
          const locationRegex = /^[a-zA-Z\s]+$/; // Allows alphabets and spaces only
          if (!value.trim()) {
            return 'Location cannot be empty.';
          }
          if (value.length < 3) {
            return 'Location must be at least 3 characters long.';
          }
          if (!locationRegex.test(value)) {
            return 'Location can only contain alphabets and spaces.';
          }
          return true;
        },
        trigger:'appoint'
      },




      // {
      //   id:'vv_des',
      //   message:"To prevent complications like blood clots, ulcers, or chronic pain. Early treatment can help manage symptoms, improve circulation, and prevent the condition from worsening, potentially avoiding more invasive procedures later on.",
      //   trigger:'appoint'
      // },
      // {
      //   id:'pad_des',
      //   message:"PAD is often linked to atherosclerosis, where plaque builds up in the arteries, and is a risk factor for heart disease and stroke.",
      //   trigger:'appoint'
      // },

      // {
      //   id:'dvt_des',
      //   message:"Deep Vein Thrombosis (DVT) is a condition where a blood clot forms in a deep vein, usually in the legs.",
      //   trigger:'appoint'
      // },
      // {
      //   id:'hc_des',
      //   message:"A heart condition refers to any disorder that affects the heart's ability to function properly, such as coronary artery disease, heart failure, arrhythmias, or valve issues.Heart conditions significantly impact vascular health, as both the heart and blood vessels work together to circulate blood throughout the body.",
      //   trigger:'appoint'
      // },

      {
        id:'appoint',
        message:'Would you like to make an appointment?',
        trigger:'sel_appoint'
      },
      {
        id:'sel_appoint',
        options:[
          {
            value:'Yes',
            label:'Yes',
            trigger:'end'
          },
          {
            value:'No',
            label:'No',
            trigger:'end'
          }
        ]
      },
      // {
      //   id:'date',
      //   message:"Please Enter Appointment Date In The Given Format (dd/mm/year)",
      //   trigger:'type_date'
      // },
      // {
      //   id:'type_date',
      //   user:true,
      //   trigger:'end'
      // },
      {
        id:'end',
        message:'Thank You For Visiting Asian Vascular Hospitals',
        end:true
      }
        ]
      



  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };



  const handleEnd =  ({steps,values}) => {

    

    try{
      

      if(values[7] === 'No' || values[7] === undefined) {
        const date =  new Date()
        const full_date = date.toLocaleDateString()
        const time = date.toLocaleTimeString()
        try{
          // console.log({name:values[1],age:values[2],Symptoms:values[3],Habits_Conditions:values[4],Phone_Number:values[5],Location:values[6],Appointment:values[7],Booked_date:full_date,Booked_time:time})
          axios.post('https://avh-database-default-rtdb.firebaseio.com/chatbot_data.json',{name:values[1],age:values[2],Disease:values[3],Habits_Conditions:values[4],Phone_Number:values[5],Location:values[6],Appointment:values[7],Register_date:full_date,Register_time:time}) 
        }
        catch(e){
          console.log(e)
        }
      }
      else{
        try{
          const date =  new Date()
          const full_date = date.toLocaleDateString()
          const time = date.toLocaleTimeString()
          axios.post('https://avh-database-default-rtdb.firebaseio.com/chatbot_data.json',{name:values[1],age:values[2],Disease:values[3],Habits_Conditions:values[4],Phone_Number:values[5],email:values[7],Location:values[8],Appointment:values[9],Register_date:full_date,Register_time:time}) 
        }
        catch(e){
          console.log(e)
        }
      }


    }
    catch(e){
      console.log(e)
    }

   };


  return (
    <div className="chatbot-container" style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '999999 ' }}>


{close && <div className="">  
    {!isOpen && <div className="chatbot-icon" onClick={toggleChatbot} useRef={chatbot1}>
        {/* 🤖 You can replace this with an actual chatbot icon */}
        <img src={robot} alt="" width={'60px'} style={{objectFit:'contain',height:'auto'}}/>
      </div>}

    <div className='floating-chatbot'>
     {isOpen && (<ThemeProvider theme={theme}>

      <ChatBot steps={steps} 
        floating={true}
        botDelay={1000}
        handleEnd={handleEnd}
        // botAvatar={'https://i.gifer.com/XOsX.gif'}
        botAvatar={bot_avatar}
        // userAvatar={'https://media2.giphy.com/avatars/HollerStudios/1l8ZK6CbbUM8.gif'} 
        userAvatar={bot_user} 
         />
        
      </ThemeProvider>)}
      
    </div>
    </div>  }


    {<div className="floating-chatbot2" ref={chatbot2}>
      <div className="floating_wrong" onClick={close_chat}>
        <i className="fa-solid fa-x"></i>
        </div>
    <div className=''>
     <ThemeProvider theme={theme}>

      <ChatBot steps={steps} 
        // floating={true}
        botDelay={1000}
        handleEnd={handleEnd}
        // botAvatar={'https://i.gifer.com/XOsX.gif'}
        botAvatar={bot_avatar}
        // userAvatar={'https://media2.giphy.com/avatars/HollerStudios/1l8ZK6CbbUM8.gif'} 
        userAvatar={bot_user} 
         />
        
      </ThemeProvider> 
    </div>
    </div>}


    </div>
  );

};


export default Chat;