import React, { useEffect, useState } from "react";
import "./Ourdoctors.css";
import Doctors from "./Doctors";
import Mainfooter from "./Mainfooter";
import praveen_kumar from "../Images/webp/Dr. G.V. Praveen Kumar.webp";
import nirupama_devi from "../Images/webp/Dr. A. Nirupama Devi.webp";
import niruba_gopinadhan from "../Images/webp/Dr. Niruba Gopinathan.webp";
import somachary from "../Images/webp/Dr. Somachary.webp";
import laxmi_narayana from "../Images/webp/Dr. A. Laxminarayana.webp";
import pramodha from "../Images/webp/Dr. G. Pramoda.webp";
import doctors_group_image from "../Images/webp/AVH_Doctors_Collage_3.2.webp";

const Ourdoctors = () => {
  const img = doctors_group_image;
  const txt = "Providers";

  useEffect(() => {
    document.title = "AVH - Our doctors";
  }, []);

  // const [load, setload] = useState(false);

  // useEffect(() => {
  //   // setTimeout(() => {
  //     setload(true);
  //   // }, 1000);
  // }, []);

  

  return (
    <div className="">
    <div>
      <Doctors image={img} txt={txt} />

      <section id="meet">
        <div className="meet_main">
          <div className="meet_left" data-aos="fade-right">
            <h1>Meet Our Specialists</h1>
          </div>
          <div className="meet_right" data-aos="fade-up">
            <h3>
              Our display of pride does not come from empty promises but from
              our prestigious panel of Doctors who savour challenges as a daily
              snack. The idioms that define our panel are patient-friendly,
              patient-centric, masters, artists, compassionate and excellence.
            </h3>
            <p>
              We are proud of our outstanding medical staff of highly-skilled
              physicians. We provide state-of-the-art care in areas such as
              family medicine, internal medicine, allergy, asthma and
              otolaryngology (ENT), cardiology, emergency and trauma medicine,
              nephrology, neurology, obstetrics and gynecology, oncology and
              hematology, ophthalmology, orthopedics, pediatrics, podiatry,
              pulmonology, radiology, surgery and urology.
            </p>
          </div>
        </div>
      </section>

      <section className="meet_images_comand">
        <div className="meet_images_main">
          <div className="meet_sub" data-aos="zoom-in">
            <img src={praveen_kumar} alt="" />
            <div className="meet_images_txt">
              <h3>Dr. G.V. Praveen Kumar</h3>
              <div className="meet_brands">
                <i className="fa-brands fa-facebook"></i>
                <i className="fa-brands fa-skype"></i>
              </div>
            </div>
            <small>MS, DNB (Vascular Surgery)</small>
            <br />
            <small> Founder Chairmen & Chief Consultant</small>
            <br />
            <small> Vascular & Endovascular Surgeon</small>
          </div>

          <div className="meet_sub" data-aos="zoom-in">
            <img src={laxmi_narayana} alt="" />
            <div className="meet_images_txt">
              <h3>Dr. A. Laxminarayana</h3>
              <div className="meet_brands">
                <i className="fa-brands fa-facebook"></i>
                <i className="fa-brands fa-skype"></i>
              </div>
            </div>
            <small>MBBS, M.CH</small>
            <br />
            <small>Cardio Thoracic Vascular Surgeon</small>
          </div>

          <div className="meet_sub" data-aos="zoom-in">
            <img src={nirupama_devi} alt="" />
            <div className="meet_images_txt">
              <h3>Dr. A. Nirupama Devi</h3>
              <div className="meet_brands">
                <i className="fa-brands fa-facebook"></i>
                <i className="fa-brands fa-skype"></i>
              </div>
            </div>
            <small>MBBS, DTCD</small>
            <br />
            <small>Managing Director & Consultant Pulmonologist</small>
          </div>

          <div className="meet_sub" data-aos="zoom-in">
            <img src={niruba_gopinadhan} alt="" />
            <div className="meet_images_txt">
              <h3>Dr. Niruba Gopinathan</h3>
              <div className="meet_brands">
                <i className="fa-brands fa-facebook"></i>
                <i className="fa-brands fa-skype"></i>
              </div>
            </div>
            <small>MBBS, MHA</small> <br />
            <small>Medical Superintendent</small>
          </div>

          <div className="meet_sub d-none" data-aos="zoom-in">
            <img src={somachary} alt="" />
            <div className="meet_images_txt">
              <h3>Dr. Somachary</h3>
              <div className="meet_brands">
                <i className="fa-brands fa-facebook"></i>
                <i className="fa-brands fa-skype"></i>
              </div>
            </div>
            <small>Vascular Surgeon</small>
          </div>

          <div className="meet_sub" data-aos="zoom-in">
            <img src={pramodha} alt="" />
            <div className="meet_images_txt">
              <h3>Dr. G. Pramoda</h3>
              <div className="meet_brands">
                <i className="fa-brands fa-facebook"></i>
                <i className="fa-brands fa-skype"></i>
              </div>
            </div>
            <small>MD (DVL), FAM</small> <br />
            <small>Consultant Medical & Cosmatic Dermatologist</small>
          </div>
        </div>
      </section>

      <div className="our_doctor_footer">
        <Mainfooter />
      </div>
    </div>
    </div>
  );
};

export default React.memo(Ourdoctors);
