import React, { useEffect, useState } from "react";
import "./About.css";
import Mainfooter from "./Mainfooter";

import trust_worthy from "../Images/Our Qualities/high-value_3852877.png";
import compassionate from "../Images/Our Qualities/respect_12564937.png";
import visionary from "../Images/Our Qualities/visionary_10930162.png";
import timely from "../Images/Our Qualities/time_1637873.png";

import asian_abt_img from "../Images/webp/asian_about_img.webp";
import asian_abt_sub_img from "../Images/webp/asian_abt_sub_img.webp";
import asian_abt_lab_img from "../Images/webp/asian_abt_lab_img.webp";

const About = () => {
  useEffect(() => {
    document.title = "AVH - About";
  }, []);

  // const [load, setload] = useState(false);

  // useEffect(() => {
  //   // setTimeout(() => {
  //     setload(true);
  //   // }, 1000);
  // }, []);

  return (
    <div className="">
    <div>
      <section id="" className="abt_sec" data-aos="fade-up">
        <div className="about_main d-flex">
          <div className="about_image">
            <div className="" data-aos="fade-right">
              <img className="about_img_1" src={asian_abt_img} alt="" />
            </div>

            <div className="" data-aos="fade-up">
              <img
                src={asian_abt_sub_img}
                alt=""
                className="about_image_card about_image_card_2"
              />
            </div>
          </div>
          <div className="about_txt">
            <div className="about_txt_1" data-aos="fade-up">
              <h1>About Asian Vascular Hospital</h1>
              <h4>
                Asian Vascular Hospitals is a premier 150 bedded vascular
                surgery center offering the highest level of vascular expertise
                alongside focused patient care. Our unparalleled patient care by
                the internationally renowned clinical acumen of care is
                complemented by the state-of-the-art technology and
                infrastructure, experienced specialists, and dedicated
                personnel.
              </h4>
            </div>
            <div className="about_txt_2" data-aos="fade-up">
              <p>
                Asian Vascular Hospitals, a dream map of Dr G V Praveen Kumar
                who had been among the panel of various renowned hospitals
                continuously sharpening his craft for over a decade and has been
                unceasingly proclaiming illustrious victories noteworthy for
                records and journals.
              </p>
              <p>
                Among his ceasing maneuvers he comprehended with the fact that
                Vascular Health went unnoticed among the majority of the masses
                of India, that lead to a grievous turn of events in a common
                man’s life.
              </p>
              <p>
                Vascular system encompassing of Veins and arteries play a
                crucial role in the functioning from head to toe influencing
                every notion of change in a human body{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="team" data-aos="fade-up">
        <div className="team_names_main d-flex">
          <div className="team_names_sub">
            <div className="names">
              <h1>Dr. G.V. Praveen Kumar</h1>
              <p>MS, DNB (Vascular Surgery)</p>
              <p>Founder Chairmen & Chief Consultant</p>
              <p>Vascular & Endovascular Surgeon</p>
            </div>
            <div className="names">
              <h1>Are Nirupama Devi</h1>
              <p>MBBS, DTCD</p>
              <p>Managing Director & Consultant Pulmonologist</p>
            </div>
          </div>

          <div className="team_names_sub">
            <div className="names">
              <h1>Dr. A. Laxminarayana</h1>
              <p>MBBS, M.CH</p>
              <p>Cardio Thoracic Vascular Surgeon</p>
            </div>
            <div className="names">
              <h1>Dr. Niruba Gopinathan</h1>
              <p>MBBS, MHA</p>
              <p>Medical Superintendent</p>
            </div>
          </div>

          <div className="team_names_sub">
            <div className="names">
              <h1>Dr. Somachary</h1>
              <p>Vascular Surgeon</p>
            </div>
            <div className="names">
              <h1>Dr. G. Pramoda</h1>
              <p>MD (DVL), FAM</p>
              <p>Consultant Medical & Cosmatic Dermatologist</p>
            </div>
          </div>
        </div>
      </div>

      <section id="About" data-aos="fade-up">
        <div className="about_main d-flex">
          <div className="about_image">
            <div className="" data-aos="fade-right">
              <img className="about_img_1" src={asian_abt_lab_img} alt="" />
            </div>
            <div className="about_image_card" data-aos="fade-up">
              <h2>We look beyond what’s possible for quality healthcare</h2>
              <h1>
                <i class="fa-solid fa-caret-down"></i>
              </h1>
            </div>
          </div>
          <div className="about_txt">
            <div className="about_txt_1" data-aos="fade-right">
              <h1>Vision & Mission</h1>
              <h4>
                Our clients are our priority, we offer quality medical services
                with a team of specialists.
              </h4>
            </div>
            <div className="about_txt_2" data-aos="fade-up">
              <p>
                Our mission is to improve the health of our community by
                providing high quality, comprehensive medical care in a
                welcoming and compassionate environment. On behalf of the entire
                staff, we would like to welcome you to our clinic. We are
                pleased to have the opportunity to assist you with your physical
                therapy care. Our goal is to provide the highest quality and
                most up-to-date physical therapy treatments available in a
                professional and caring manner. We are committed to helping you
                attain your rehabilitation goals. It is also our goal to provide
                you with outstanding service.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="quality">
        <div className="">
          <div className="doctors_head mb-5 qualities_head" data-aos="fade-up">
            <h1 className="">Our Qualities</h1>
          </div>

          <div className="quality_icons">
            <div className="quality_icons_card">
              <img src={trust_worthy} alt="" />
              <h4>Trustworthy</h4>
            </div>
            <div className="quality_icons_card">
              <img src={visionary} alt="" />
              <h4>Visionary</h4>
            </div>
            <div className="quality_icons_card">
              <img src={compassionate} alt="" />
              <h4>Compassionate</h4>
            </div>
            <div className="quality_icons_card">
              <img src={timely} alt="" />
              <h4>Timely</h4>
            </div>
          </div>
        </div>
      </section>

      <div className="Contact_footer">
        <Mainfooter />
      </div>
    </div>
    </div>
  );
};

export default React.memo(About);
