/** @format */

import React, { useEffect, useState } from "react";
import "./Appointment.css";
import axios from "axios";
// import Doctors from './Doctors';
import help_desk from "../Images/webp/Asian Vascular Hospitals_HelpDesk_edit.7133eaa935b2b399de4e.webp";
// import Footer from '../Components/Footer'
import Mainfooter from "./Mainfooter";
import Doctors2 from "./Doctors2";

import con_image from "../Images/webp/pexels-pavel-danilyuk-7108317.jpg";

const Appointement = () => {
  const img =
    "https://www.eternalhospital.com/wp-content/02/book-an-appointment.jpg";
  const txt = "Appointment";
  const date_zone = new Date();
  const [data, setdata] = useState({
    First_Name: "",
    Family_Name: "",
    Phone_Number: "",
    Email: "",
    Subject: "",
    Questions: "",
    Register_Date: date_zone.toLocaleDateString(),
    Register_Time: date_zone.toLocaleTimeString(),
  });

  const handledata = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    // await axios.post('https://api.sheetbest.com/sheets/1c949d21-6149-4fc2-912f-a1e763e3310c',data)
    axios.post(
      "https://avh-database-default-rtdb.firebaseio.com/form_data.json",
      data
    );
    setdata({
      First_Name: "",
      Family_Name: "",
      Phone_Number: "",
      Email: "",
      Subject: "",
      Questions: "",
    });
  };

  // const aa = async ()=>{
  //     const api = await axios.post('https://script.google.com/macros/s/AKfycby2jiMNfekEpGWSJUUbfYhmpaSg2JtIz1p0uNjTUrL32FYn-4uwvgF5cltpr9ZjcXqh/exec')
  //     console.log(api.data)
  // }

  useEffect(() => {
    document.title = "AVH - Appointment";
    // aa()
  }, []);

  return (
    <div>
      <div className="contact_req">
        <Doctors2 image={img} txt={txt} />
      </div>

      <section className="appoint_cmd">
        <div className="appoint_main">
          <img
            src={
              "https://images.pexels.com/photos/7108317/pexels-photo-7108317.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            }
            alt=""
          />

          <div className="cnt_main" data-aos="fade-up">
            <div className="cnt_sub">
              <div className="cnt_hd">
                <h1>Contact Form</h1>

                <form className="cnt_cnt_form" onSubmit={handlesubmit}>
                  <input
                    type="text"
                    placeholder="First Name"
                    onChange={handledata}
                    name="First_Name"
                    value={data.First_Name}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Family Name"
                    onChange={handledata}
                    name="Family_Name"
                    value={data.Family_Name}
                    required
                  />
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    onChange={handledata}
                    name="Phone_Number"
                    value={data.Phone_Number}
                    pattern="^\d{10}$"
                    maxLength={10}
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={handledata}
                    name="Email"
                    value={data.Email}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Subject"
                    onChange={handledata}
                    name="Subject"
                    value={data.Subject}
                    required
                  />
                  <textarea
                    name="Questions"
                    id=""
                    placeholder="Questions"
                    onChange={handledata}
                    value={data.Questions}
                    required
                  ></textarea>
                  <button className="btn send_btn" type="submit">
                    <i className="fa-solid fa-envelope"></i> Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="appoint_footer">
        <Mainfooter />
      </div>
    </div>
  );
};

export default Appointement;
