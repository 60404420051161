import React from 'react';
import './Insurance.css'
import insurance from '../Images/insurance.webp'
import family_health from '../Images/Insurance/1.Family Health Paln Ltd(FHPL).webp' 
import mediassist from '../Images/Insurance/2.Mediassit India Pvt ltd.webp' 
import care_general from '../Images/Insurance/3.Care General Insurance Co.Ltd.webp' 
import bajaj from '../Images/Insurance/5.Bajaj Allianz.webp' 
import reliance_life from '../Images/Insurance/6.Reliance Life Insurance Co.Ltd.webp' 
import paramount from '../Images/Insurance/7.Paramount HealthcareServices.webp' 
import heritage from '../Images/Insurance/8.Heritage Health Services Pvt Ltd.webp' 
import vidal_health from '../Images/Insurance/9.Vidal Health Care Pvt Ltd.webp' 
import good_health from '../Images/Insurance/10.Good Health Plan Ltd.webp' 
import md_india from '../Images/Insurance/11.MD India TPA PVT LTD.webp' 
import raksha_health from '../Images/Insurance/12.Raksha Health Insurance Tpa.webp' 
import magma_hdi from '../Images/Insurance/13.Magma HDI General Insurance Co.Ltd.webp' 
import safeway from '../Images/Insurance/14.Safeway TPA.webp' 
import health_insurance from '../Images/Insurance/15.Health Insurance TPA Of INDIA Ltd.webp' 
import medesava from '../Images/Insurance/16.Medseva TPA.webp' 
import health_india_ from '../Images/Insurance/17.Health India TPA.webp' 
import volo_insurance from '../Images/Insurance/18.Volo Insurance TPA.webp' 
import the_new_india from '../Images/Insurance/19.The New India Insurance.webp' 
import oriental_insurance from '../Images/Insurance/20.Oriental Insurance.webp' 
import national_insurance from '../Images/Insurance/21.National Insurance.webp' 
import united_india from '../Images/Insurance/22.UNITED INDIA INSURANCE.webp' 
import sbi_general from '../Images/Insurance/23.SBI General Insurance.webp' 
import cghs from '../Images/Insurance/26.CGHS.webp' 
import singareni from '../Images/Insurance/25.Singareni Collieries.webp' 

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Insurance = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 10,
        slidesToScroll: 10,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 2000, // Adjust for large screens
                settings: {
                    slidesToShow: 6, // Show 3 cards at a time
                    slidesToScroll: 6, // Scroll 2 cards per transition
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1500, // Adjust for large screens
                settings: {
                    slidesToShow: 5, // Show 3 cards at a time
                    slidesToScroll: 5, // Scroll 2 cards per transition
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1100, // Adjust for large screens
                settings: {
                    slidesToShow: 4, // Show 3 cards at a time
                    slidesToScroll: 4, // Scroll 2 cards per transition
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024, // Adjust for large screens
                settings: {
                    slidesToShow: 6, // Show 3 cards at a time
                    slidesToScroll: 6, // Scroll 2 cards per transition
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768, // Adjust for medium screens (tablets)
                settings: {
                    slidesToShow: 4, // Show 2 cards at a time
                    slidesToScroll: 4, // Scroll 1 card per transition
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 450, // Adjust for medium screens (tablets)
                settings: {
                    slidesToShow: 3, // Show 2 cards at a time
                    slidesToScroll: 3, // Scroll 1 card per transition
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 310, // Adjust for medium screens (tablets)
                settings: {
                    slidesToShow: 2, // Show 2 cards at a time
                    slidesToScroll: 2, // Scroll 1 card per transition
                    infinite: true,
                    dots: false
                }
            },
        ]
        
        

    };

    const cards = [
        { id: 1, image:family_health},
        { id: 2, image:mediassist},
        { id: 3, image:care_general},
        { id: 4, image:bajaj},
        { id: 5, image:reliance_life},
        { id: 6, image:paramount},
        { id: 7, image:heritage},
        { id: 8, image:vidal_health},
        { id: 9, image:good_health},
        { id: 10, image:md_india},
        { id: 11, image:raksha_health},
        { id: 12, image:magma_hdi},
        { id: 13, image:safeway},
        { id: 14, image:medesava},
        { id: 16, image:health_india_},
        { id: 17, image:volo_insurance},
        { id: 18, image:the_new_india},
        { id: 19, image:oriental_insurance},
        { id: 20, image:national_insurance},
        { id: 21, image:united_india},
        { id: 22, image:sbi_general},
        { id: 23, image:cghs},
        { id: 24, image:singareni},
        { id: 25, image:health_insurance}

    ];

    return (
        <div className='ins_sec' data-aos="fade-up">
            <div  style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
            <h3 style={{textAlign:"center",width:"800px",fontStyle:"italic"}}>"Quality care should be accessible to everyone. To abide by this purpose, we engage an extensive range of insurance agencies."</h3>
            </div>
            <section className="ins_main">
                <div className="" >
                    <img src={insurance} alt="" className='ins'/>
                </div>
  




<Slider {...settings} className='slider'>
                {cards.map(card => (
                    <div key={card.id} style={{ padding: '10px' }} className='slider_card ins_slide_card' >

                        <img src={card.image} alt="" />
                    </div>
                ))}
            </Slider>
            

          




            </section>


        </div>
    );
};

export default React.memo(Insurance);