import React, { useRef, useState } from 'react';
import './Doctors.css'
import { Link } from 'react-router-dom';
import { FaHandHoldingMedical } from "react-icons/fa";
import axios from 'axios';



const Doctors = (p) => {

    const donevis = useRef()
    const formdis = useRef()
    // const donevis = useRef()

    const [data,setdata] = useState({
        First_Name:'',
        Family_Name:'',
        Phone_Number:'',
        Email:'',
        Subject:'',
        Questions:''
    })

    const handledata = (e) =>{
        setdata({...data,[e.target.name]:e.target.value})
    }
    const handlesubmit = async(e) =>{
        e.preventDefault()

        await axios.post('https://avh-database-default-rtdb.firebaseio.com/form_data.json',data)
        setdata({
            First_Name:'',
            Family_Name:'',
            Phone_Number:'',
            Email:'',
            Subject:'',
            Questions:''
        })
        donevis.current.style.display = 'block'

    }

    const donehide =  () =>{
        donevis.current.style.display = 'none'
      
    }

      const formopen = () =>{
        formdis.current.classList.add('formvis')
      }
      const forminvis = () =>{
        formdis.current.classList.remove('formvis')
      }

    return (
        <div className='get_care_main'>
            <div className="get_care">
                <img src={p.image} alt="" />
                <div className="get_contain_main">
                    <div className="get_icons_main">
                    <div className="get_icons">
                        <div className="get_icons_sub d-flex align-items-center justify-content-center gap-3">
                            <i className='fa-solid fa-print'></i>
                            <i className='fa-solid fa-envelope'></i>
                            <i className='fa-solid fa-share'></i>
                        </div>
                    </div>
                    </div>
                <div className="get_care_container">
                    <div className="get_container_sub">
                        <div className="get_txt">
                        <h2>GET CARE</h2>
                        <h1>{p.txt}</h1>
                        </div>

                
                        <div className="get_appoint d-flex justify-content-between align-items-center">
                            <span className='btn req_btn' onClick={formopen}>Request Appointement <i className='fa-solid fa-angle-right'></i></span>
                        <h4 className='d-flex align-items-center gap-3'><FaHandHoldingMedical className='i'/> <span>+91 733 732 1814</span></h4>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="nv_cntct_main" ref={formdis}>
           <div className="cnt_main  nv_cnct" data-aos="zoom-in" >
                            <div className="cnt_sub">
                                <div className="cnt_hd d-grid justify-content-center">
                                  <div className="text-end mb-1" onClick={forminvis}>
                                    <i className="fa-solid fa-x form_wrng" style={{marginTop:'-20px'}}></i>
                                  </div>

                                    <h1 style={{textAlign:'center'}}>Request an Appointment</h1>

                                    <form className="cnt_cnt_form " onSubmit={handlesubmit}>
                                    <input type="text" placeholder='First Name' onChange={handledata} name='First_Name' value={data.First_Name} required/>
                                        <input type="text" placeholder='Family Name' onChange={handledata} name='Family_Name' value={data.Family_Name} required/>
                                        <input type="tel" placeholder='Phone Number' onChange={handledata} name='Phone_Number' value={data.Phone_Number} maxLength={10} pattern="^\d{10}$" required/>
                                        <input type="email" placeholder='Email' onChange={handledata} name='Email' value={data.Email} required/>
                                        <input type="text" placeholder='Subject' onChange={handledata} name='Subject' value={data.Subject} required/>
                                        <textarea name="Questions" id="" placeholder='Questions' onChange={handledata}  value={data.Questions} required></textarea>
                                        <button className='btn send_btn send_btn_2' type='submit'><i className='fa-solid fa-envelope' ></i> Request Appointment</button>
                                    </form>
                                </div>
                            </div>
              </div>
              </div>


              <div className="done_main" ref={donevis}>
              <div className="done">
              <i class="fa-solid fa-check"></i>
                  <span>Booked Successfully</span>
                <button className="btn send_btn" onClick={donehide}>Done</button>
              </div>
              </div>
        </div>
    );
};

export default React.memo(Doctors);