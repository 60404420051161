import React from 'react';

const Error = () => {
    return (
        <div>
            <div className="">
                <h1>404</h1>
                <p>Page Not Found</p>
            </div>
        </div>
    );
};

export default Error;